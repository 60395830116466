<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/services/9-1/B14-20190411_01.jpg">
					<img class="" src="@/assets/images/services/9-1/B14-20190411_02.jpg">
					<img class="" src="@/assets/images/services/9-1/B14-20190411_03.jpg">
					<img class="" src="@/assets/images/services/9-1/B14-20190411_04.jpg">
					<img class="" src="@/assets/images/services/9-1/B14-20190411_05.jpg">
					<img class="" src="@/assets/images/services/9-1/B14-20190411_06.jpg">
					
					
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"安全攻防培训服务",
			content:"网站被入侵、篡改、挂马、拖库、勒索事件、AOT攻击、CIA/NSA等核武器级工具持续曝光，安全形势日趋严峻、攻击技术日新月异，安全人员攻防技术急需提升。",
            cc:"为客户提供安全攻防培训服务，安全攻防培训能让安全人员快速掌握安全攻防技术，更好的预防",
			price:'在线咨询',
			url:require('../../assets/images/services/9-1/171_G_1527823077431.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}





}


</style>







